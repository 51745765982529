import React from "react"
import { graphql } from "gatsby";
import Layout from "../layouts/Layout"

import SiteMetadata from "../components/SiteMetadata"

const TermsAndConditions = ({ data, location }) => {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { html, frontmatter } = markdownRemark
  console.log(data.markdown)

  return (
    <Layout data={data.meta} location={location}>
      <SiteMetadata title="Terms and Conditions" description="Terms and Conditions| ChonkerKeys" />

      <div className="container py-32 mx-auto">
        <h1 className="text-5xl my-20 text-center">{frontmatter.title}</h1>
        <div className="w-full pb-8 md:pb-0 drop-shadow-sm">
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </div>
    </Layout>
  )
}

export default TermsAndConditions

export const query = graphql`
  query TermsAndConditions {
    meta: site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    markdownRemark(frontmatter: {slug: {eq: "eula-terms-and-conditions"}}) {
        html
        frontmatter {
            title
        }
    }
  }
`
